<template>
  <div class="container">
    <div class="promotionBox">
      <div class="paddingPub pro_title">
        <div class="head">{{ $t('policy.title') }}</div>
        <div class="desc">{{ $t('policy.desc') }}</div>
      </div>
      <div class="paddingPub contentBox">
        <div class="content">
          <p class="headTop">{{ $t('policy.yuanze.head') }}</p>
          <ol>
            <li>{{ $t('policy.yuanze.l1') }}</li>
            <li>{{ $t('policy.yuanze.l2') }}</li>
            <li>{{ $t('policy.yuanze.l3') }}</li>
            <li>{{ $t('policy.yuanze.l4') }}</li>
            <li>{{ $t('policy.yuanze.l5') }}</li>
            <li>{{ $t('policy.yuanze.l6') }}</li>
            <li>{{ $t('policy.yuanze.l7') }}</li>
          </ol>
          <strong>{{ $t('policy.sfzy.sfzy') }}</strong>
          <p class="headTop">{{ $t('policy.sfzy.ziliao') }}</p>
          <strong>{{ $t('policy.sfzy.anquan') }}</strong>
          <ol>
            <li>{{ $t('policy.sfzy.an1') }}</li>
            <li>{{ $t('policy.sfzy.an2') }}</li>
            <li>{{ $t('policy.sfzy.an3') }}</li>
          </ol>
          <strong>{{ $t('policy.sfzy.baozheng') }}</strong>
          <ol>
            <li>{{ $t('policy.sfzy.bao1') }}</li>
            <li>{{ $t('policy.sfzy.bao2') }}</li>
          </ol>
          <strong>{{ $t('policy.gytz.head') }}</strong>
          <ol>
            <li>{{ $t('policy.gytz.l1') }}</li>
            <li>{{ $t('policy.gytz.l2') }}</li>
            <li>
              {{ $t('policy.gytz.l3') }}
              <ol type="a">
                <li>{{ $t('policy.gytz.l3_1') }}</li>
                <li>{{ $t('policy.gytz.l3_2') }}</li>
                <li>{{ $t('policy.gytz.l3_3') }}</li>
                <li>{{ $t('policy.gytz.l3_4') }}</li>
                <li>{{ $t('policy.gytz.l3_5') }}</li>
              </ol>
            </li>
          </ol>
          <p class="headTop">{{ $t('policy.wmyw') }}</p>
          <ol>
            <li>
              {{ $t('policy.wm1') }}
              <ol type="a">
                <li>{{ $t('policy.wm1_1') }}</li>
                <li>{{ $t('policy.wm1_2') }}</li>
                <li>{{ $t('policy.wm1_3') }}</li>
                <li>{{ $t('policy.wm1_4') }}</li>
                <li>{{ $t('policy.wm1_5') }}</li>
                <li>{{ $t('policy.wm1_6') }}</li>
                <li>{{ $t('policy.wm1_7') }}</li>
                <li>{{ $t('policy.wm1_8') }}</li>
                <li>{{ $t('policy.wm1_9') }}</li>
                <li>{{ $t('policy.wm1_10') }}</li>
              </ol>
            </li>
            <li>{{ $t('policy.wm2') }}</li>
            <li>{{ $t('policy.wm3') }}</li>
            <li>
              {{ $t('policy.wm4') }}
              <ol type="a">
                <li>{{ $t('policy.wm4_1') }}</li>
                <li>{{ $t('policy.wm4_2') }}</li>
                <li>{{ $t('policy.wm4_3') }}</li>
                <li>{{ $t('policy.wm4_4') }}</li>
                <li>{{ $t('policy.wm4_5') }}</li>
                <li>{{ $t('policy.wm4_6') }}</li>
                <li>{{ $t('policy.wm4_7') }}</li>
                <li>{{ $t('policy.wm4_8') }}</li>
                <li>{{ $t('policy.wm4_9') }}</li>
                <li>{{ $t('policy.wm4_10') }}</li>
                <li>{{ $t('policy.wm4_11') }}</li>
                <li>
                  {{ $t('policy.wm4_12') }}
                  <ol type="i">
                    <li>{{ $t('policy.wm4_12_1') }}</li>
                    <li>{{ $t('policy.wm4_12_2') }}</li>
                    <li>{{ $t('policy.wm4_12_3') }}</li>
                  </ol>
                </li>
                <li>{{ $t('policy.wm4_13') }}</li>
                <li>{{ $t('policy.wm4_14') }}</li>
                <li>{{ $t('policy.wm4_15') }}</li>
                <li>{{ $t('policy.wm4_16') }}</li>
                <li>{{ $t('policy.wm4_17') }}</li>
                <li>{{ $t('policy.wm4_18') }}</li>
                <li>{{ $t('policy.wm4_19') }}</li>
                <li>{{ $t('policy.wm4_20') }}</li>
              </ol>
            </li>
            <li>
              {{ $t('policy.wm5') }}
              <ol type="a">
                <li>{{ $t('policy.wm5_1') }}</li>
                <li>{{ $t('policy.wm5_2') }}</li>
                <li>{{ $t('policy.wm5_3') }}</li>
                <li>{{ $t('policy.wm5_4') }}</li>
                <li>{{ $t('policy.wm5_5') }}</li>
                <li>{{ $t('policy.wm5_6') }}</li>
                <li>{{ $t('policy.wm5_7') }}</li>
                <li>{{ $t('policy.wm5_8') }}</li>
                <li>
                  {{ $t('policy.wm5_9') }}
                  <ol type="i">
                    <li>{{ $t('policy.wm5_9_1') }}</li>
                    <li>{{ $t('policy.wm5_9_2') }}</li>
                    <li>{{ $t('policy.wm5_9_3') }}</li>
                    <li>{{ $t('policy.wm5_9_4') }}</li>
                    <li>{{ $t('policy.wm5_9_5') }}</li>
                    <li>{{ $t('policy.wm5_9_6') }}</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <p class="headTop">{{ $t('policy.bushi.head') }}</p>
          <ol>
            <li>
              {{ $t('policy.bushi.l1') }}
              <ol type="a">
                <li>{{ $t('policy.bushi.l1_1') }}</li>
                <li>{{ $t('policy.bushi.l1_2') }}</li>
                <li>{{ $t('policy.bushi.l1_3') }}</li>
                <li>{{ $t('policy.bushi.l1_4') }}</li>
              </ol>
            </li>
          </ol>
          <p class="headTop">{{ $t('policy.hezhunguiding.head') }}</p>
          <ol>
            <li>
              {{ $t('policy.hezhunguiding.l1') }}
              <ol type="a">
                <li>{{ $t('policy.hezhunguiding.l1_1') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_2') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_3') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_4') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_5') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_6') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_7') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_8') }}</li>
                <li>{{ $t('policy.hezhunguiding.l1_9') }}</li>
              </ol>
            </li>
          </ol>
          <p class="headTop">{{ $t('policy.zhijiecuxiao.head') }}</p>
          <ol>
            <li>
              {{ $t('policy.zhijiecuxiao.l1') }}
              <p class="headTop">{{ $t('policy.zhijiecuxiao.liHead') }}</p>
              <ol type="a">
                <li>{{ $t('policy.zhijiecuxiao.l1_1') }}</li>
                <li>
                  {{ $t('policy.zhijiecuxiao.l1_2') }}
                  <ol type="i">
                    <li>{{ $t('policy.zhijiecuxiao.l1_2_1') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_2_2') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_2_3') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_2_4') }}</li>
                  </ol>
                </li>
                <li>
                  {{ $t('policy.zhijiecuxiao.l1_3') }}
                  <ol type="i">
                    <li>{{ $t('policy.zhijiecuxiao.l1_3_1') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_3_2') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_3_3') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_3_4') }}</li>
                    <li>{{ $t('policy.zhijiecuxiao.l1_3_5') }}</li>
                  </ol>
                </li>
                <li>{{ $t('policy.zhijiecuxiao.l1_4') }}</li>
                <li>{{ $t('policy.zhijiecuxiao.l1_5') }}</li>
              </ol>
            </li>
          </ol>
          <p class="headTop">{{ $t('policy.buyuanyi.head') }}</p>
          <ol>
            <li>
              {{ $t('policy.buyuanyi.l1') }}
              <ol type="a">
                <li>{{ $t('policy.buyuanyi.l1_1') }}</li>
                <li>{{ $t('policy.buyuanyi.l1_2') }}</li>
                <li>{{ $t('policy.buyuanyi.l1_3') }}</li>
                <li>{{ $t('policy.buyuanyi.l1_4') }}</li>
                <li>{{ $t('policy.buyuanyi.l1_5') }}</li>
              </ol>
            </li>
            <li>{{ $t('policy.buyuanyi.l2') }}</li>
            <li>{{ $t('policy.buyuanyi.l3') }}</li>
            <li>{{ $t('policy.buyuanyi.l4') }}</li>
            <li>{{ $t('policy.buyuanyi.l5') }}</li>
          </ol>
        </div>
        <div class="content">
          <p class="headTop text-right">
            {{ $t('policy.ziliaobaozhang.zhuren') }}<br>
            {{ $t('policy.ziliaobaozhang.gongsi') }}<br>
            {{ $t('policy.ziliaobaozhang.dizhi') }}<br>
            {{ $t('policy.ziliaobaozhang.dianhua') }}
          </p>
          <ol>
            <li>{{ $t('policy.ziliaobaozhang.l1') }}</li>
            <li>{{ $t('policy.ziliaobaozhang.l2') }}</li>
          </ol>
          <p class="headTop text-right">日期：2022年5月</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Policy',
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.promotionBox {
  position: relative;
  padding-bottom: 34px;
  &::after{
    width: 66%;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 2px dashed #000B8C;
  }
  .pro_title{
    color: #ffffff;
    line-height: 58px;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: #091490;
    background-image: url("../assets/images/policy_bg.png");
    background-position: right -4%;
    background-repeat: no-repeat;
    background-size: 35% 130%;
    .head{
      font-size: 32px;
      font-weight: bold;
    }
    .desc{
      font-size: 16px;
    }
  }
}
.contentBox{
  padding-top: 4%;
  padding-bottom: 8%;
  color: #303030;
  .title{
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .content {
    .headTop{
      margin-bottom: 16px;
    }
    strong{
      display: inline-block;
      margin-bottom: 8px;
      font-size: 15px;
    }
    ol {
      color: #000000;
      line-height: 28px;
    }
  }
}
@media screen and (max-width: 576px) {
  .promotionBox{
    &::after{
      width: 0;
    }
  }
}
</style>
